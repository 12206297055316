import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Testimonial from "./components/Testimonial";
import CTA from "./components/CTA";
import Footer from "./components/Footer";

function App() {
  /* particlesJS.load(@dom-id, @path-json, @callback (optional)); */
  window.particlesJS.load("particles-js", "assets/particles.json", function () {
    console.log("callback - particles.js config loaded");
  });
  return (
    <>
      <Header />
      <Hero />
      <Services />
      {/* <Testimonial /> */}
      <CTA />
      <Footer />
    </>
  );
}

export default App;
