import React from "react";
import backgroundImage from "../assets/images/backgroundHero.jpg";

export default function Hero() {
  return (
    <section>
      <div className="container">
        <div
          id="particles-js"
          className="hero h-96 md:h-[500px] rounded-box overflow-hidden"
          /* style={{
            backgroundImage: `url(${backgroundImage})`,
          }} */
        >
          <div className="hero-overlay bg-opacity-90 bg-black"></div>
          <div className="hero-content text-center text-secondary-content">
            <div className="max-w-lg">
              <h1 className="mb-5 sm:mb-7 text-4xl sm:text-5xl font-bold">
                No te preocupes por la facturación y los procesos.
              </h1>
              <p className="mb-5 sm:mb-7 sm:text-lg">
                Con nuestros servicios API puedes ahorrar tiempo, asegurando la
                sencillez de tus procesos de negocio con la facturación online u
                offline.
              </p>
              <button className="btn btn-warning sm:btn-wide">
                Empezar ➡️
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
