import React from "react";

export default function Services() {
  return (
    <section className="py-10 md:py-16">
      <div className="container">
        <div className="text-center">
          <h2 className="text-3xl sm:text-5xl font-bold mb-4">
            Lo que ofrecemos
          </h2>
          <p className="text-lg sm:text-2xl mb-6 md:mb-14">
            Ahorra tiempo administrando y consumiendo nuestros servicios API.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 lg:gap-8 xl:gap-10">
          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-receipt text-4xl"></i>
              <h2 className="card-title">Facturaci&oacute;n (Alpha)</h2>
              <p>
                Servicio de facturaci&oacute;n online individual y
                m&uacute;ltiples documentos sectores.
              </p>
              {/* <p>
                This is a wider card with <br className="hidden xl:inline" />
                supporting text below as a <br className="hidden xl:inline" />{" "}
                natural content.
              </p> */}
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-archive text-4xl"></i>
              <h2 className="card-title">Cat&aacute;logos (Beta)</h2>
              <p>Servicio de catalogos consultables.</p>
              {/* <p>
                This is a wider card with <br className="hidden xl:inline" />
                supporting text below as a <br className="hidden xl:inline" />{" "}
                natural content.
              </p> */}
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-bezier text-4xl"></i>
              <h2 className="card-title">Operaciones (Gamma)</h2>
              <p>Servicio de Operaciones de Facturación.</p>
              {/* <p>
                This is a wider card with <br className="hidden xl:inline" />
                supporting text below as a <br className="hidden xl:inline" />{" "}
                natural content.
              </p> */}
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-cart text-4xl"></i>
              <h2 className="card-title">Compras (Epsilon)</h2>
              <p>Servicio de operaciones para Compras y Tasa Cero.</p>
              {/* <p>
                This is a wider card with <br className="hidden xl:inline" />
                supporting text below as a <br className="hidden xl:inline" />{" "}
                natural content.
              </p> */}
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-postcard text-4xl"></i>
              <h2 className="card-title">C&oacute;digos (Zeta)</h2>
              <p>
                Servicio de Obtención de Códigos de Facturación como Cuis y Cufd
                entre otros.
              </p>
              {/* <p>
                This is a wider card with <br className="hidden xl:inline" />
                supporting text below as a <br className="hidden xl:inline" />{" "}
                natural content.
              </p> */}
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-fingerprint text-4xl"></i>
              <h2 className="card-title">Auth (Shadow)</h2>
              <p>
                Servicios de autenticación API, para asegurar sus datos y
                consumos transparentes.
              </p>
              {/* <p>
                This is a wider card with <br className="hidden xl:inline" />
                supporting text below as a <br className="hidden xl:inline" />{" "}
                natural content.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
