import React from "react";

export default function Footer() {
  return (
    <footer className="bg-base-200 text-base-content ">
      <div className="container ">
        <div className="footer py-10 md:py-16 grid-cols-2 sm:grid-cols-4 lg:grid-cols-auto">
          <div>
            <span className="footer-title">Soluciones</span>
            <a href="#!" className="link link-hover">
              Tu sitio web
            </a>
            <a href="#!" className="link link-hover">
              Marketing
            </a>
            <a href="#!" className="link link-hover">
              Software a medida
            </a>
            <a href="#!" className="link link-hover">
              Integraciones
            </a>
          </div>
          <div>
            <span className="footer-title">Servicios</span>
            <a href="#!" className="link link-hover">
              ERP - Facturación
            </a>
            <a href="#!" className="link link-hover">
              Sampi
            </a>
            <a href="#!" className="link link-hover">
              Unirte a nuestro equipo
            </a>
          </div>
          <div>
            <span className="footer-title">Company</span>
            <a href="#!" className="link link-hover">
              Acerca de nosotros
            </a>
            <a href="#!" className="link link-hover">
              Contacto
            </a>
            <a href="#!" className="link link-hover">
              Empleo
            </a>
            <a href="#!" className="link link-hover">
              Servicios
            </a>
          </div>
          <div>
            <span className="footer-title">Legal</span>
            <a href="#!" className="link link-hover">
              T&eacute;rminos y Condiciones
            </a>
            <a href="#!" className="link link-hover">
              Pol&iacute;tica de privacidad
            </a>
            <a href="#!" className="link link-hover">
              Cookies
            </a>
          </div>
          <div className="w-full col-span-full md:col-auto">
            <span className="footer-title">Recibe Noticias</span>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Ingresa tu email</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  placeholder="username@site.com"
                  className="input input-bordered w-full pr-16"
                />
                <button className="btn btn-primary absolute top-0 right-0 rounded-l-none">
                  Suscribirse
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-center border-t border-base-300 py-4 gap-2">
          <div className="flex-grow text-center sm:text-start">
            <p>© 20224 RDA-Consultores, SA. All rights reserved.</p>
          </div>
          <div className="grid grid-flow-col gap-4">
            <a className="link link-secondary" href="#!">
              <i className="bi bi-facebook text-xl"></i>
            </a>
            <a className="link link-secondary" href="#!">
              <i className="bi bi-instagram text-xl"></i>
            </a>
            <a className="link link-secondary" href="#!">
              <i className="bi bi-twitter text-xl"></i>
            </a>
            <a className="link link-secondary" href="#!">
              <i className="bi bi-github text-xl"></i>
            </a>
            <a className="link link-secondary" href="#!">
              <i className="bi bi-dribbble text-xl"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
